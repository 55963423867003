import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import Router from 'next/router'; // https://stackoverflow.com/questions/69203538/useeffect-dependencies-when-using-nextjs-router
import qs from 'qs';

import { logger } from 'Services/logger/logger';
import { isSSR } from 'Utilities/helpers';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';

const getPath = ({ pathname, query }: { pathname: string; query: Record<string, unknown> }) =>
	`${pathname}${qs.stringify(query, { addQueryPrefix: true })}`;

export const MirageWrapper = ({ children }: { children: ReactNode }) => {
	const isMirageServerEnabled = useFeatureToggle(FEATURES.mirageServer);
	// If Cypress tests are checking the url changes, don't allow mirage server booting to hijack the url
	const isMirageSoftLaunchEnabled = useFeatureToggle(FEATURES.mirageServerSoftLaunch);
	const isAnalyticsEventLoggerEnabled = useFeatureToggle(FEATURES.analyticsEventLogger);
	const [isHydratedWithMirage, setIsHydratedWithMirage] = useState(false);

	useEffect(() => {
		if (isMirageServerEnabled) {
			const setupMirageServer = async () => {
				window.mirageServer?.shutdown();
				const createServer = await import('Mirage/server')
					.then((module) => module.default)
					.catch((error) =>
						logger.error({
							error,
							message: error?.message || 'There was an issue with loading Mirage server',
							scope: 'mirage/server',
						}),
					);

				const { Cypress } = window;
				const mirageSettings = Cypress ? { state: 'cypress', timing: 0 } : { state: 'default' };
				if (typeof createServer === 'function') {
					window.mirageServer = createServer(mirageSettings);
				}
				if (!Cypress) {
					window.mirageServer.logging = !isAnalyticsEventLoggerEnabled;
				}

				void Router.replace(getPath(Router), Router.asPath, { shallow: isMirageSoftLaunchEnabled });
				setIsHydratedWithMirage(true);
			};
			setupMirageServer().catch((error) =>
				logger.error({
					error,
					message: error?.message || 'There was an issue with setting up Mirage server',
					scope: 'mirage/server',
				}),
			);
		} else if (window.mirageServer?.shutdown) {
			window.mirageServer.shutdown();
			void Router.replace(getPath(Router), Router.asPath);
			Router.reload();
		}
	}, [isAnalyticsEventLoggerEnabled, isMirageServerEnabled, isMirageSoftLaunchEnabled]);

	if (isMirageServerEnabled) {
		if (isSSR()) return null;
		if (!isHydratedWithMirage) return null;
	}

	return <>{children}</>;
};
